<template>
  <div id="AddUser" class="row mx-0">
    <div class="row mx-0">
      <div id="header" class="col-sm-12 col-md-12 color-bg-4">
        <Header :name="user.fullname" />
      </div>
      <div class="col-sm-12 col-md-12">
        <div id="content" class="row color-bg-4">
          <div id="" class="">
            <SideMenu :indexsec="9" />
          </div>
          <div id="stn-edituser" class="stn_edituserAdd">
            <form
              @submit.prevent="submit(data.title, data.url, date_form, file1)"
            >
              <div class="div_EProduct">
                <p>EDITAR VIDEO</p>
              </div>

              <a
                class="btn-ver"
                :href="'/video/' + data.urltitle"
                target="_blank"
              >
                VER VIDEO <img src="../../assets/Arrow_6.png" alt="" />
              </a>
              <div class="mtrow"></div>

              <div class="row mx-0 row-one">
                <div class="EPF2_C1">
                  <label class="labelN color-1" for="inputN">Título</label>
                  <b-form-input
                    v-model="data.title"
                    required
                    id="inputN"
                    type="text"
                  />
                </div>
                <div class="">
                  <label class="labelA color-1" for="inputA"
                    >Fecha
                    <span class="f_actual"
                      >actual: {{ data.datevideo }}</span
                    ></label
                  >
                  <b-form-input v-model="date_form" id="inputA" type="date" />
                </div>

                <div class="div-image-video">
                  <label class="labelImg color-1" for="inputImg"
                    >Imagen Video</label
                  >
                  <b-form-file
                    v-model="file1"
                    id="inputImg"
                    type="file"
                    name="inputImg"
                    :placeholder="'Subir imagen'"
                  />
                </div>
              </div>

              <div id="edit-video-img" v-if="msgimg == 'success'">
                <img :src="this.image(data.image)" alt="imagen video" />
              </div>

              <div class="row mx-0 row-one">
                <div class="iframe">
                  <label class="labelifr color-1" for="inputifr"
                    >INSERTAR Iframe</label
                  >
                  <p class="p1">
                    Agrega el iframe proporcionado por el sitio web del cual vas
                    a compartir el video.
                  </p>
                  <p class="p2">
                    El iframe usualmente se encuentra en la opción insertar del
                    compartir.
                  </p>
                  <b-form-input
                    v-model="data.url"
                    id="inputifr"
                    type="text"
                    placeholder="iframe"
                  />
                </div>
              </div>

              <label class="labelA color-1 mtrow EPF1_C1centrado">Video</label>
              <vue-editor
                id="editor"
                class="quill-editor"
                v-model="data.url"
                :editorToolbar="toolbar()"
              >
              </vue-editor>

              <div class="row mx-0 row-one">
                <div class="div-error" v-if="this.status == 'error'">
                  <p class="msg-error">{{ this.msg }}</p>
                </div>
              </div>

              <div class="btn-form">
                <b-btn
                  variant="outline-secondary"
                  class="editar-modal "
                  type="submit"
                  >GUARDAR</b-btn
                >
                <b-modal
                  id="modal-editar"
                  ref="modal-editar"
                  no-close-on-backdrop
                  centered
                  hide-footer
                  hide-header
                >
                  <div class="message-modal">
                    <img :src="IconSuccess" class="success" /><br />
                    <p class="text-modal color-6">
                      El video se edito correctamente
                    </p>
                  </div>
                  <div class="">
                    <b-button class="btn-modal" @click="agregarOtro()"
                      >ACEPTAR</b-button
                    >
                    <b-button
                      type="button"
                      aria-label="Close"
                      class="close-modal"
                      @click="salir()"
                      >×</b-button
                    >
                  </div>
                </b-modal>
              </div>
            </form>

            <hr class="mtrow" />
            <label id="Label_AgrCanales" class="labelpr color-1"
              >Agregar Categorías</label
            >

            <div v-if="this.section == 'categorias'" class="row mx-0 row-one">
              <div class="div-error" v-if="this.status == 'error'">
                <p class="msg-error">{{ this.msg }}</p>
              </div>
            </div>

            <form @submit.prevent="agregarCategoria(category_form)">
              <div class="row mx-0 row-two">
                <div class="div_Canal">
                  <label id="label_subtitle" class="labelEq color-1"
                    >Categoría</label
                  >

                  <b-dropdown>
                    <template #button-content>
                      <div class="row">
                        <span class="drop-text">{{ category_name }}</span>
                      </div>
                    </template>

                    <div v-for="(item, key) in this.categories" :key="key">
                      <b-dropdown-item
                        @click="changeCategory(item.id, item.name)"
                      >
                        {{ item.name }}
                      </b-dropdown-item>
                    </div>
                  </b-dropdown>
                </div>
              </div>

              <div id="EditPartbtn_Agregar" class="btn-form-2">
                <b-btn
                  variant="outline-secondary"
                  class="editar-modal"
                  type="submit"
                  >AGREGAR</b-btn
                >
                <b-modal
                  id="modal-editar"
                  ref="modal-editar"
                  no-close-on-backdrop
                  centered
                  hide-footer
                  hide-header
                >
                  <div class="message-modal">
                    <img :src="IconSuccess" class="success" /><br />
                    <p class="text-modal color-6">{{ message_modal }}</p>
                  </div>
                  <div class="">
                    <b-button class="btn-modal" @click="wait()"
                      >ACEPTAR</b-button
                    >
                    <b-button
                      type="button"
                      aria-label="Close"
                      class="close-modal"
                      @click="salir()"
                      >×</b-button
                    >
                  </div>
                </b-modal>
              </div>
            </form>

            <div class="canales">
              <div id="div_canales" class="row channel_titles">
                <div>
                  <p>ID</p>
                </div>
                <div>
                  <p>Nombre</p>
                </div>

                <div>
                  <p>Eliminar</p>
                </div>
              </div>

              <div v-if="data.categories != 'No se encontraron coincidencias'">
                <div
                  v-for="(item, key) in data.categories"
                  class="item_channel"
                  :key="key"
                >
                  <div class="row">
                    <div>
                      <p>{{ item.id }}</p>
                    </div>
                    <div>
                      <p>{{ item.category_name }}</p>
                    </div>

                    <div>
                      <p
                        class="eliminar-channel"
                        @click="eliminarCategoria(item.id)"
                      >
                        &#10006;
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else>
                <div class="mtrow msg_channels">
                  <p>Este video aun no tiene categorías asignadas.</p>
                </div>
              </div>
            </div>

            <label h2 id="prev-title" class="pvz-title color-1 EPF1_C1centrado"
              >Previsualización</label
            >
            <div id="prev_quill" v-html="data.url"></div>

            <label id="prev-title" class="html-title color-1 EPF1_C1centrado"
              >HTML</label
            >
            <div id="prev_quill">
              {{ data.url }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SideMenu from "../../components/SideMenu";
import logo from "../../assets/cimarrones/logocima_2.png";
import IconSuccess from "../../assets/Vector.png";
import legrafica from "../../assets/Legrafica.png";
import Header from "../../components/Header";
import { mapActions } from "vuex";

import { VueEditor } from "vue2-editor";
export default {
  name: "EditGallery",
  components: {
    Header,
    SideMenu,
    VueEditor,
  },
  data() {
    return {
      file1: null,
      date_form: "",
      status: "",
      msg: "",
      msgimg: "success",
      logo: logo,
      legrafica: legrafica,
      IconSuccess: IconSuccess,
      section: "",
      category_form: "",
      category_name: "categoría",
      message_modal: "",
    };
  },
  async beforeMount() {
    let token = await this.$store.dispatch("admin/getToken");
    this.$store.dispatch("admin/decode", token);
  },
  created() {
    let id = this.$route.params.id;
    let payload = {
      id: id,
      option: "videos",
    };
    this.getInfoById(payload);
  },
  computed: {
    data() {
      return this.$store.getters["main/data"];
    },
    url() {
      return this.$store.getters["main/baseURL"];
    },
    user() {
      return this.$store.getters["admin/getIdentity"];
    },
    categories() {
      let allctg = this.$store.getters["categories/data"];
      let result = [];
      let model;
      for (var i = 0; i < allctg.length; i++) {
        if (
          allctg[i].parent_name != "TIENDA" &&
          allctg[i].name != "TIENDA" &&
          allctg[i].name != "ROPA" &&
          allctg[i].parent_name != "ROPA"
        ) {
          //Mostrar solo categorias para ropa.
          if (allctg[i].id == 1) {
            model = {
              id: allctg[i].id,
              id_parent: allctg[i].id_parent,
              name: "NINGUNA",
              parent_name: allctg[i].parent_name,
            };
          } else {
            model = {
              id: allctg[i].id,
              id_parent: allctg[i].id_parent,
              name: allctg[i].name,
              parent_name: allctg[i].parent_name,
            };
          }

          result.push(model);
        }
      }

      return result;
    },
  },
  methods: {
    ...mapActions("main", ["getInfoById"]),
    wait: function() {
      setTimeout(() => this.$router.go(), 200);
    },
    submit: async function(title, url, date, image) {
      let id = this.$route.params.id;
      let videos = this.data;
      this.status = "";
      this.msg = "";

      if (date == "") {
        date = videos.datevideo;
      }
      if (url == "" || url == undefined || url == null) {
        url = videos.url;
      }
      if (image == null) {
        let data = {
          id: id,
          title: title,
          datevideo: date,
          url: url,
        };
        this.status = "";
        this.msg = "";

        let result = await this.$store.dispatch("main/editItem", {
          option: "videos",
          item: data,
        });

        if (result.status == "error") {
          this.status = "error";
          this.msg = result.message;
        } else {
          // success
          this.content = "";
          this.showModal();
        }
      } else {
        if (image.size > 1000000) {
          this.status = "error";
          this.msg =
            "Tamaño de imagen no válido, sube imagenes menores a 1 MB.";
        } else {
          var data = new FormData();
          data.append("id", id);
          data.append("image", image);
          data.append("title", title);
          data.append("url", url);
          data.append("datevideo", date);
          data.append("_method", "PUT");

          this.status = "";
          this.msg = "";

          let result = await this.$store.dispatch("main/editItem", {
            option: "videos",
            item: data,
          });

          if (result.status == "error") {
            this.status = "error";
            this.msg = result.message;
          } else {
            // success
            this.content = "";
            this.showModal();
          }
        }
      }
    },
    agregarOtro: function() {
      this.wait();
    },
    salir: function() {
      this.$router.push("/administrador").catch((err) => {});
    },
    showModal() {
      this.$refs["modal-editar"].show();
    },
    toolbar: function() {
      return [["video"]];
    },

    image: function(img) {
      let src = this.url + "/get-video-image/" + img;
      this.status_img(img);
      return src;
    },
    status_img: async function(img) {
      let userimg = "";
      try {
        userimg = await this.$store.dispatch("main/getImage", {
          image: img,
          option: "video",
        });
        if (userimg.data.status == "error") {
          this.msgimg = "error";
        } else {
          this.msgimg = "success";
        }
      } catch (err) {
        this.msgimg = "error";
      }
    },
    eliminarCategoria: async function(id) {
      let option = "videos/category";
      let result = await this.$store.dispatch("main/deleteItem", {
        option: option,
        id: id,
      });

      if (result.status == "error") {
        this.section = "categorias";
        this.msg = result.message;
        this.showModalError();
      } else {
        this.section == "";
        this.msg = "";
        this.wait();
      }
    },
    agregarCategoria: async function(id_category) {
      let id_video = this.$route.params.id;
      this.message_modal = "La categoria se agrego correctamente.";

      /*
        console.log(id_blog)
        console.log(id_category)*/

      if (
        id_category == "" ||
        id_category == undefined ||
        id_category == null
      ) {
        this.section = "categorias";
        this.status = "error";
        this.msg = "Elige una categoría.";
      } else {
        id_video = String(id_video);
        id_category = String(id_category);
        let data = {
          id_category: id_category,
          id_video: id_video,
        };

        this.status = "";
        this.msg = "";

        let result = await this.$store.dispatch("main/addItem", {
          option: "videos/category",
          item: data,
        });

        if (result.status == "error") {
          this.section = "categorias";
          this.status = "error";
          this.msg = result.message;
        } else {
          // success
          this.showModal();
        }
      }
    },

    changeCategory: function(value, name) {
      this.category_form = value;
      this.category_name = name;
    },
  },
};
</script>

<style scoped>
.iframe {
  margin-top: 2vw;
  margin-left: 10vw;
}
.iframe input {
  width: 53.4vw !important;
}

.iframe p.p1 {
  color: var(--color-5);
  margin-bottom: 0vw;
  font-size: 0.9vw;
}

.iframe p.p2 {
  color: var(--color-3);
  font-size: 0.5vw;
  width: 54vw;
}
</style>
